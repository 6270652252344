import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"

const QuoraPage = () => (
  <Layout>
      <div class="nav_bar">
        <Link to="">About</Link>
        <Link to="/quora/" activeClassName="active">Work</Link>
      </div>
      <div class="work_nav_bar">
        <Link to="/quora/" class="work_links" activeClassName="active">Quora</Link>
        <Link to="/facebook/" class="work_links">Facebook</Link>
        <Link to="/wanelo/" class="work_links">Wanelo</Link>
      </div>
      <h1>Quora</h1>
      <ul class="list">
            <li>2017 - 2019</li>
        </ul>
        <p>I'm currently working at Quora as a product designer. 
        Designers at Quora have a unique role relative to other companies; 
        we implement the front-end code for all of our designs, ship and test our own experiments, 
        and are typically responsible for the project management of our projects. 
        While at Quora I have worked on our answer writing tools, our interfaces and app navigation, 
        and the question asking experience.</p>
        <iframe src="https://player.vimeo.com/video/347392207?autoplay=1&loop=1&autopause=0" width="240" height="426" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
        <iframe src="https://player.vimeo.com/video/347400199?autoplay=1&loop=1&autopause=0" width="240" height="426" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
        <iframe src="https://player.vimeo.com/video/347400148?autoplay=1&loop=1&autopause=0" width="240" height="426" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>

        <h2>Partner Program</h2>
        <ul class="list">
            <li>Role: Product Designer and Team Lead</li>
            <li>2018 - 2019</li>
        </ul>
        <p>The Quora Partner Program was introduced in 2018 to invite users who add good, 
        useful questions to the site to share ad revenue earned on those questions. 
        I joined the team in mid-2018 and worked on tools to help Partners understand how much they're earning 
        from their questions and how well their questions are performing.</p>

        <h2>App Navigation</h2>
        <ul class="list">
            <li>Role: Product Designer and Team Lead</li>
            <li>2018</li>
        </ul>
        <p>.</p>

        <h2>Editor</h2>
        <ul class="list">
            <li>Role: Product Designer</li>
            <li>2017 - 2018</li>
        </ul>
        <p>I worked on a redesign of the answer editor to better support adding photos to answers and recording video answers. 
        I also did some explorations around making mentioning and linking of content much more robust and easy to do.</p>
  </Layout>
)

export default QuoraPage